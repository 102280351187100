<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          large
          rounded
          fixed
          right
          bottom
          v-bind="attrs"
          v-on="on"
        >
          <v-icon light>mdi-account-plus</v-icon>
          Add Parent
        </v-btn>
      </template>

      <v-card>
        <v-form @submit.prevent="submit">
          <v-card-title>
            <span class="text-h5">Add Parent</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    label="First name*"
                    v-model="name"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    label="Last name*"
                    v-model="surname"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-text-field
                    label="ID Number*"
                    v-model="idNumber"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    label="Cellphone Number*"
                    v-model="cellphone"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    label="Email*"
                    v-model="email"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Password*"
                    type="password"
                    v-model="password"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false">
              Close
            </v-btn>
            <v-btn color="blue darken-1" text @click="submit"> Save </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  data() {
    return {
      dialog: false,
      parentObj: Object,
      name: "",
      surname: "",
      email: "",
      cellphone: "",
      password: "",
      token: "",
      idNumber: "",
      action: "REGISTER",
    };
  },
  created() {
  },
  methods: {
    submit() {
      this.parentObj = {
        name: this.name,
        surname: this.surname,
        email: this.email,
        cellphone: this.cellphone,
        password: this.password,
        token: "",
        idNumber: this.idNumber,
        action: this.action,
      };

      this.$store.dispatch("addParent", this.parentObj)

      this.dialog = false;
      // this.connection.send(JSON.stringify(this.parentObj));
    },
  },
};
</script>

<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn text rounded small v-bind="attrs" v-on="on"
        ><v-icon light small left>mdi-account-edit</v-icon>Edit Details</v-btn
      >
      <!-- <v-btn color="primary" dark large rounded fixed right bottom v-bind="attrs" v-on="on">
        <v-icon light>mdi-account-plus</v-icon>
          Edit Parent
        </v-btn> -->
    </template>

    <v-card>
      <v-form @submit.prevent="submit">
        <v-card-title>
          <span class="text-h5">Edit Parent</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="First name*"
                  v-model="name"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Last name*"
                  v-model="surname"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field
                  label="ID Number*"
                  v-model="idNumber"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Cellphone Number*"
                  v-model="cellphone"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  label="Email*"
                  v-model="email"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Password*"
                  type="password"
                  v-model="password"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Close
          </v-btn>
          <v-btn color="blue darken-1" text @click="submit"> Save </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: ['parentObj'],
  data() {
    return {
      dialog: false,
      parent: {},
      name: this.parentObj.name,
      surname: this.parentObj.surname,
      email: this.parentObj.email,
      cellphone: this.parentObj.cellphone,
      password: "",
      token: "",
      idNumber: "",
      action: "",
    };
  },
  created() {
    console.log("Starting connection to WebSocket Server");
    this.connection = new WebSocket("ws://localhost:3344/ ");
    // this.connection = new WebSocket("wss://app.quickloc8.co.za:3344")

    this.connection.onopen = (event) => {
      console.log(
        "Successfully connected to the websocket server..." + event.data
      );
    };

    this.connection.onclose = (event) => {
      console.log("Connection closed websocket server..." + event.data);
    };

    this.connection.onmessage = (event) => {
      console.log("Message received from Websocket" + event.data);
      let parsedData = JSON.parse(event.data);
      if (parsedData.code === 200) {
        console.log("Successfully Added parent");
      }
    };

    this.connection.onerror = (event) => {
      console.log("Error connecting to websocket server..." + event.data);
    };
  },
  methods: {
    submit() {
      this.parent = {
        name: this.name,
        surname: this.surname,
        email: this.email,
        cellphone: this.cellphone,
        password: this.password,
        token: "",
        idNumber: this.idNumber,
        action: this.action,
      };

      this.connection.send(JSON.stringify(this.parent));
    },
  },
};
</script>

import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Parents from "../views/Parents.vue";
import Auth from "../views/Auth.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { requiresAuth: true }
  },
  {
    path: "/parents",
    name: "Parents",
    component: Parents,
    meta: { requiresAuth: true }
  },
  {
    path: "/auth",
    name: "Auth",
    component: Auth,
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem('auth')

  if(to.matched.some(record => record.meta.requiresAuth)) {
    console.log('here 1');
    if(!loggedIn){
      next('/auth')
    } else {
      next()
    }
  } else {
    if(to.name == "Auth" && loggedIn){
      next('/parents')
    }
    next()
  }
})

export default router;

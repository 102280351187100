<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :items="parents"
      :items-per-page="10"
      :single-expand="true"
      :expanded.sync="expanded"
      item-key="cellphone"
      show-expand
      class="elevation-1 mb-16"
    >
      <!-- <template v-slot:expanded-item="{ headers, item }"> -->
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" style="text-align: right">
          <!-- {{ item.name }} {{ item.surname }} -->
          <v-spacer></v-spacer>

          <!-- <v-btn text rounded small v-bind="attrs" v-on="on"><v-icon light small left>mdi-account-edit</v-icon>Edit {{ item.name }} {{ item.surname }}</v-btn> -->
          <EditParent :parentObj="item" />
          <AddChild :parentObj="item" />
          <RemoveChild :parentObj="item" />
          <UpdateAddress :parentObj="item" />
        </td>
      </template>
    </v-data-table>
    <AddParent />
  </v-container>
</template>
<script>
import AddParent from "@/components/AddParent.vue";
import EditParent from "@/components/EditParent.vue";
import UpdateAddress from "@/components/UpdateAddress.vue";
import RemoveChild from "@/components/RemoveChild.vue";
import AddChild from "@/components/AddChild.vue";
import { authComputed } from "../store/helpers.js";
export default {
  name: "Parents",
  components: {
    AddParent,
    EditParent,
    UpdateAddress,
    RemoveChild,
    AddChild,
  },
  data() {
    return {
      expanded: [],
      dialog: false,
      headers: [
        { text: "Name", align: "start", value: "name" },
        { text: "Surname", value: "surname" },
        { text: "Address", value: "address" },
        { text: "# of Children", value: "numberOfChildren" },
        { text: "Cellphone", value: "cellphone" },
        { text: "Email", value: "email" },
        { text: "Actions", align: "center", value: "data-table-expand" },
      ],
      parents: [],
      parentsRequestObj: {},
    };
  },
  methods: {},
  created() {
    console.log("Starting connection to WebSocket Server");
    // this.connection = new WebSocket("ws://localhost:3344/");
    this.connection = new WebSocket("wss://app.quickloc8.co.za:3344")

    this.connection.onopen = () => {
      console.log("Successfully connected to the websocket server...");

      this.parentsRequestObj = {
        action: "GETPARENTS",
        username: this.$store.state.auth.auth.username
      };

      console.log("parent object" + JSON.stringify(this.parentsRequestObj));

      //todo: check whether ws is open before sending authObject, !if, connect to ws server first
      this.connection.send(JSON.stringify(this.parentsRequestObj));
    };

    this.connection.onclose = (event) => {
      console.log("Connection closed websocket server..." + event.data);
    };

    this.connection.onmessage = (event) => {
      console.log("Message received from Websocket" + event.data);
      let parsedData = JSON.parse(event.data);
      if (parsedData.length >= 0) {
        console.log("Successfully fetched parents" + parsedData);
        this.parents.push.apply(this.parents, parsedData);
      }
    };

    this.connection.onerror = (event) => {
      console.log("Error connecting to websocket server..." + event.data);
    };
  },
  computed: {
    ...authComputed,
  },
};
</script>

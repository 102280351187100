export default {
  namespaced: true,
  state: {
    auth: null,
    // notificationObj: {
    //   notificationType: 'info', //info, success, warning, error
    //   showNotification: false,
    //   notificationMsg: 'message here'
    // } 
  },
  mutations: {
    SET_AUTH(state, auth) {
        
        if(auth.length == 1){
            state.auth = auth[0];
            localStorage.setItem("auth", JSON.stringify(auth[0]));
            location.reload();
        } else {
            //handle error
        }   

    },
    UNSET_AUTH(state) {
      localStorage.removeItem("auth");
      state.auth = null //because we added vuex-persitentstate to keep state alive during refresh
      location.reload(); //clears all state and headers etc. so we don't need state.auth = null and forces router guard to redirect to auth page
    },
    // NOTIFY(state, msg){
    //   console.log("NOTIFY CALLED");
    //   state.notificationObj = {
    //     showNotification: true,
    //     notificationMsg: msg,
    //     notificationType: 'info'
    //   }
    // }
  },
  actions: {
    checkAuth({ commit, dispatch }, credentials) {
      console.log("Starting connection to WebSocket Server");

    //  const connection = new WebSocket("ws://localhost:3344/")
     const connection = new WebSocket("wss://app.quickloc8.co.za:3344")

      connection.onopen = (event) => {
        console.log("Successfully connected to the websocket server: bubbles " + event);
        // this.connection.send(JSON.stringify(credentials));
        connection.send(JSON.stringify(credentials));
      };

      connection.onclose = (event) => {
        console.log("Connection closed websocket server: code " + event.code );
        console.log("Connection closed websocket server: reason " + event.reason );
        console.log("Connection closed websocket server: wasClean " + event.wasClean );
      };

      connection.onmessage = (event) => {
        console.log("Message received from Websocket" + event.data);
        let authObj = JSON.parse(event.data)
        if (authObj) {
          console.log("Successfully signed in");
          // todo: set $store global values to contain user details
          commit("SET_AUTH", authObj);

          //commit("NOTIFY", "Successfully signed in");

          dispatch('notification/show', { msg: "Successfully signed in" }, {root: true})
          // TODO: CLOSE CONNECTION AFTER USE!!
          // this.connection.close()
        }
      };

      connection.onerror = (event) => {
        console.log("Error connecting to websocket server..." + event.data);
      };
    },
    logout({ commit, dispatch }) {
      commit("UNSET_AUTH");

      // commit("NOTIFY", "You have been signed out");
      dispatch('notification/show', { msg: "You have been signed out" }, {root: true})
    },
  },
  getters: {
    loggedIn(state) {
      console.log("LOGIN STATUS " + !!state.auth);
      console.log("Local Storage: " + localStorage.getItem('auth'));
      return !!state.auth;
    },
    currentUser(state) {
      return state.auth;
    },
    // notificationState(state) {
    //   return state.notificationObj;
    // }
  },
};

export default {
    state: {
        //keep states of every action for 'UNDO' actions
        parent: {},
        address: {},
        children: {},
        child: {}
    },
    mutations: {
        ADD_PARENT(state, parent) {            
            if(parent){
                state.parent = parent;
            } else {
                //handle error (maybe, not here)
            }   
        },
        UPDATE_ADDRESS(state, address) {            
            if(address){
                state.address = address;
            } else {
                //handle error (maybe, not here)
            }   
        },
        REMOVE_CHILDREN(state, children) {            
            if(children){
                state.children = children;
            } else {
                //handle error (maybe, not here)
            }   
        },
        ADD_CHILD(state, child) {            
            if(child){
                state.child = child;
            } else {
                //handle error (maybe, not here)
            }   
        },
    },
    actions: {
        addParent({commit, dispatch}, parentObj){
            console.log("Starting connection to WebSocket Server");
            const connection = new WebSocket("wss://app.quickloc8.co.za:3344")

            connection.onopen = (event) => {
                console.log("Successfully connected to the websocket server..." + event.data);
                console.log(JSON.stringify(parentObj));
                connection.send(JSON.stringify(parentObj))
            };

            connection.onclose = (event) => {
                console.log("Connection closed websocket server..." + event.data);
            };

            connection.onmessage = (event) => {
                console.log("Message received from Websocket" + event.data); // "Parent Added Succesfully" message sent from server
                if(event.data){
                    commit("ADD_PARENT", parentObj);
                    // commit("NOTIFY", event.data);
                    dispatch('notification/show', { msg: event.data }, {root: true})
                    location.reload()
                }

            };

            connection.onerror = (event) => {
                console.log("Error connecting to websocket server..." + event.data);
            };

        },
        updateAddress({commit, dispatch}, addressObj){
            console.log("Starting connection to WebSocket Server");
            const connection = new WebSocket("wss://app.quickloc8.co.za:3344")

            connection.onopen = (event) => {
                console.log("Successfully connected to the websocket server..." + event.data);
                console.log(JSON.stringify(addressObj));
                connection.send(JSON.stringify(addressObj))
            };

            connection.onclose = (event) => {
                console.log("Connection closed websocket server..." + event.data);
            };

            connection.onmessage = (event) => {
                console.log("Message received from Websocket" + event.data); // "UPDATE ADDRESS Succesfully" message sent from server
                if(event.data){
                    commit("UPDATE_ADDRESS", addressObj);
                    // commit("NOTIFY", event.data);
                    dispatch('notification/show', { msg: event.data }, {root: true})
                    location.reload()
                }

            };

            connection.onerror = (event) => {
                console.log("Error connecting to websocket server..." + event.data);
            };

        },
        removeChildren({commit, dispatch}, childrenObj){
            console.log("Starting connection to WebSocket Server");
            const connection = new WebSocket("wss://app.quickloc8.co.za:3344")

            connection.onopen = (event) => {
                console.log("Successfully connected to the websocket server..." + event.data);
                console.log(JSON.stringify(childrenObj));
                connection.send(JSON.stringify(childrenObj))
            };

            connection.onclose = (event) => {
                console.log("Connection closed websocket server..." + event.data);
            };

            connection.onmessage = (event) => {
                console.log("Message received from Websocket" + event.data); // "CHILD REMOVED Succesfully" message sent from server
                if(event.data){
                    commit("REMOVE_CHILDREN", childrenObj);
                    // commit("NOTIFY", event.data);
                    dispatch('notification/show', { msg: event.data }, {root: true})
                    location.reload()
                }

            };

            connection.onerror = (event) => {
                console.log("Error connecting to websocket server..." + event.data);
            };

        },
        addChild({commit, dispatch}, childObj){
            console.log("Starting connection to WebSocket Server");
            const connection = new WebSocket("wss://app.quickloc8.co.za:3344")

            connection.onopen = (event) => {
                console.log("Successfully connected to the websocket server..." + event.data);
                console.log(JSON.stringify(childObj));
                connection.send(JSON.stringify(childObj))
            };

            connection.onclose = (event) => {
                console.log("Connection closed websocket server..." + event.data);
            };

            connection.onmessage = (event) => {
                console.log("Message received from Websocket" + event.data); // "CHILD Added Succesfully" message sent from server
                if(event.data){
                    commit("ADD_CHILD", childObj);
                    // commit("NOTIFY", event.data);
                    dispatch('notification/show', { msg: event.data }, {root: true})
                    location.reload()
                }

            };

            connection.onerror = (event) => {
                console.log("Error connecting to websocket server..." + event.data);
            };

        },
    },
    getters: {
      
    }
  };
  
<template>
  <v-dialog v-model="dialog" persistent max-width="950px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn text rounded small v-bind="attrs" v-on="on"
        ><v-icon light small left>mdi-account-remove</v-icon>Remove child</v-btn
      >
    </template>

    <v-card>
      <v-card-title>
        <span class="text-h5">Remove child: {{ parentName }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <!-- Place modal content here  -->
          <v-data-table
            v-model="selected"
            :headers="headers"
            :items="children"
            :items-per-page="5"
            :single-select="false"
            item-key="name"
            show-select
            class="elevation-1"
          >
          </v-data-table>
        </v-container>
        <small>All selected children will be removed</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary darken-1" text @click="dialog = false">
          Close
        </v-btn>
        <v-btn color="error darken-1" text @click="removeChildren">
          Remove
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['parentObj'],
  data() {
    return {
      dialog: false,
      selected: [],
      headers: [
        { text: "First Name", align: "start", value: "name" },
        { text: "Surname", value: "surname" },
        { text: "ID Number", value: "idNumber" },
        { text: "School name", value: "schoolName" },
        { text: "School Address", value: "schoolAddress" }
      ],
      children: [],
      action: 'REMOVECHILDREN'
    };
  },
  created() {
  
  },
  watch:{
     dialog(isShown){
       if(isShown){
         console.log('dialog open');
         console.log("Starting connection to WebSocket Server");

          let connection = new WebSocket("wss://app.quickloc8.co.za:3344")

          connection.onopen = () => {
            console.log("Successfully connected to the websocket server...");

            this.childrenRequestObj = {
              action: "GETCHILDREN",
              cellphone: this.parentObj.cellphone
            };

            console.log("children object" + JSON.stringify(this.childrenRequestObj));

            connection.send(JSON.stringify(this.childrenRequestObj ));
          };

          connection.onclose = (event) => {
            console.log("Connection closed websocket server..." + event.data);
          };

          connection.onmessage = (event) => {
            console.log("Message received from Websocket" + event.data);
            let parsedData = JSON.parse(event.data);
            if (parsedData.length >= 0) {
              console.log(parsedData);
              this.children.push.apply(this.children, parsedData);
            }
          };

          connection.onerror = (event) => {
            console.log("Error connecting to websocket server..." + event.data);
          };
          
       } else {
         console.log('dialog closed');
         // clear children arr.
         this.children.length = 0;
       }
     }
  },
  methods:{
    removeChildren(){

      let childrenToRemove = {
        action: this.action,
        cellphone: this.parentObj.cellphone,
        idNumbers: this.selected.map(item => item.idNumber)
      }

      // this.connection.send(JSON.stringify(childrenToRemove));

      this.$store.dispatch("removeChildren", childrenToRemove)

      this.dialog = false;

    }
  },
  computed:{
    parentName(){
      return this.parentObj.name + ' ' + this.parentObj.surname
    }
  }
};
</script>
<style scoped></style>

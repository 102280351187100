<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <!-- <v-btn color="primary" dark large fixed right bottom v-bind="attrs" v-on="on">
        <v-icon light>mdi-account-plus</v-icon>
          Update Address
        </v-btn> -->

      <v-btn text rounded small v-bind="attrs" v-on="on"
        ><v-icon light small left>mdi-map-marker-question</v-icon>Update
        address</v-btn
      >
    </template>

    <v-card>
      <v-form @submit.prevent="submit">
        <v-card-title>
          <span class="text-h5">Update Address: {{ parentName }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Cellphone Number*"
                  v-model="cellphone"
                  required
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Address"
                  v-model="address"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field
                  label="Longitude*"
                  v-model="longitude"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Latitude*"
                  v-model="latitude"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Close
          </v-btn>
          <v-btn color="blue darken-1" text @click="submit"> Save </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: ['parentObj'],
  name: "UpdateAdress",
  data() {
    return {
      dialog: false,
      addressObj: {},
      cellphone: this.parentObj.cellphone,
      address: "",
      latitude: "",
      longitude: "",
      action: "SAVEPARENTHOMEADDRESS",
    };
  },
  computed: {
    parentName(){
      return this.parentObj.name + ' ' + this.parentObj.surname;
    }
  },
  created() {

  },
  methods: {
    submit() {
      this.addressObj = {
        cellphone: this.cellphone,
        address: this.address,
        latitude: this.latitude,
        longitude: this.longitude,
        action: this.action,
      };

      this.$store.dispatch("updateAddress", this.addressObj)

      this.dialog = false;
      // this.connection.send(JSON.stringify(this.addressObj));
    },
  },
};
</script>
<style scoped></style>

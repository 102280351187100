import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

import auth from "@/store/modules/auth.js";
import notification from "@/store/modules/notification.js";
import parents from "@/store/modules/parents.js";

const dataState = createPersistedState({
  paths: ['auth', 'notification']
})

export default new Vuex.Store({
  modules: {
    auth,
    notification,
    parents
  },
  plugins: [dataState]
});

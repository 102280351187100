<template>
  <v-snackbar 
      align-center
      v-model="snackbar"
      :color="color" 
      :timeout="timeout"
      rounded="pill"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          rounded
          v-bind="attrs"
          @click="snackbar = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
</template>

<script>

export default {
    props:['notificationState'],
    data() {
        return {
            timeout: 2500,
            snackbar: this.notificationState.showNotification,
            text: this.notificationState.notificationMsg,
            color: this.notificationState.notificationType
        }
    },
    computed: {
        // ...authComputed
    }

    }
</script>

<style scoped>

</style>
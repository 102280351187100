<template>
  <v-app id="inspire">
    <v-navigation-drawer v-if="loggedIn" v-model="drawer" app>
      <v-sheet color="grey lighten-4" class="pa-4">
        <v-img
          :src="require('../assets/renablue.png')"
          class="my-3"
          contain
          height="30"
        />
        <div v-if="loggedIn" class="text-center caption"><i>{{ activeUser }}</i></div>
      </v-sheet>
      <v-divider></v-divider>
      <v-list>
        <v-list-item
          v-for="[icon, text] in links"
          :key="icon"
          link
          :to="{ name: text }"
        >
          <v-list-item-icon>
            <v-icon>{{ icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>
      <v-list>
        <v-list-item link @click="logout">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Sign out</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar v-if="loggedIn" app>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>{{ currentRouteName }}</v-toolbar-title>
    </v-app-bar>

    <v-main>
      <!-- <h1>{{ notificationState }}</h1> -->
      <router-view />
      <BaseSnackbar :notificationState="notificationState"/>
    </v-main>
  </v-app>
</template>

<script>
import { authComputed } from "../store/helpers.js";
export default {
  name: "NavBar",
  data() {
    return {
      drawer: null,
      links: [
        ["mdi-account-supervisor", "Parents"],
      ],
    };
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
    },
  },
  computed: {
    currentRouteName(){
      return this.$route.name
    },
    activeUser() {
      return (
        this.$store.state.auth.auth.name +
        " " +
        this.$store.state.auth.auth.surname
      );
    },
    ...authComputed,
  },
};
</script>

<style></style>

export default {
    namespaced: true,
    state: {
      notificationObj: {
        notificationType: 'info', //info, success, warning, error
        showNotification: false,
        notificationMsg: 'message here'
      } 
    },
    mutations: {
      NOTIFY(state, msg){
        console.log("NOTIFY CALLED"+ msg);
        state.notificationObj = {
          showNotification: true,
          notificationMsg: msg,
          notificationType: 'success'
        }
      }
    },
    actions: {
    //   show({commit}){
        show({commit}, msgObj){

        console.log("HITING NOTIFICATION.JS " + msgObj);
        commit("NOTIFY", msgObj.msg);
      }
    },
    getters: {
      notificationState(state) {
        return state.notificationObj;
      }
    },
  };
  
<template>
  <h1>Rena dashboard</h1>
</template>

<script>

export default {
  name: "Home",
};
</script>

<template>
  <v-card elevation="2" width="500" class="mx-auto mt-10" v-if="!loggedIn">
    <v-form @submit.prevent="submit">
      <v-card-title>Rena Admin</v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="Username*"
                v-model="username"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <!-- <v-text-field label="Password*" type="password" append-icon="mdi-eye-off" required ></v-text-field> -->
              <v-text-field
                label="Password*"
                v-model="password"
                type="password"
                append-icon="mdi-eye-off"
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions>
        <!-- <v-btn text rounded @click="logout">Log out</v-btn> -->
        <v-spacer></v-spacer>
        <v-btn rounded text large color="green darken-1" @click="checkAuth"
          >Login</v-btn
        >
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { authComputed } from "../store/helpers.js";
export default {
  name: "Auth",
  data() {
    return {
      // connection: null,
      authObject: Object,
      username: "",
      password: "",
      // username: "rena@quickloc8.co.za",
      // password: "@renaAdmin99",
      
    };
  },
  created() {
  },
  methods: {
    checkAuth() {
      //TODO: DO NOT HARCODE
      this.authObject = {
        action: "ADMINLOGIN",
        username: this.username,
        password: this.password,
      };

      this.$store.dispatch("auth/checkAuth", this.authObject)

    },
    logout() {
      this.$store.commit("UNSET_AUTH");
    },
  },
  computed: {
    ...authComputed
  }
};
</script>

<style></style>

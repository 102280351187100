<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn text rounded small v-bind="attrs" v-on="on"
        ><v-icon light small left>mdi-account-plus</v-icon>Add child</v-btn>
    </template>

    <v-card>
      <v-form @submit.prevent="submit">
        <v-card-title>
          <span class="text-h5">Add Child</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="First name*"
                  v-model="firstName"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Last name*"
                  v-model="surname"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field
                  label="ID Number*"
                  v-model="idNumber"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Cellphone Number*"
                  v-model="cellphone"
                  required
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Tag Mac Address*"
                  v-model="tagMacAddress"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="School name*"
                  v-model="schoolName"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="School Address*"
                  v-model="schoolAddress"
                  required
                ></v-text-field>
              </v-col>

              

              <v-col cols="12" sm="6">
                <v-text-field
                  label="Longitude*"
                  v-model="longitude"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Latitude*"
                  v-model="latitude"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Close
          </v-btn>
          <v-btn color="blue darken-1" text @click="submit"> Save </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: ['parentObj'],
  data() {
    return {
      dialog: false,
      childObject: {},
      firstName: "",
      surname: "",
      idNumber: "",
      cellphone: this.parentObj.cellphone,
      schoolName: "",
      schoolAddress: "",
      longitude: "",
      latitude: "",
      tagMacAddress: "",
      action: "ADDCHILD",
    };
  },
  created() {

  },
  methods: {
    submit() {
      this.childObject = {
        cellphone: this.cellphone,
        scholarData: {
          firstName: this.firstName,
          surname: this.surname,
          idNumber: this.idNumber,
          schoolName: this.schoolName,
          schoolAddress: this.schoolAddress,
          schoolLocation: {
            longitude: this.longitude,
            latitude: this.latitude,
          },
          tagMacAddress: this.tagMacAddress,
        },
        action: this.action,
      };

      // this.connection.send(JSON.stringify(this.childObject));

      this.$store.dispatch("addChild", this.childObject)

      this.dialog = false;
    },
  },
};
</script>
